




























































import { Component, Vue } from 'vue-property-decorator'
import { Action, namespace } from 'vuex-class'

import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Card from 'primevue/card'

Vue.component('InputText', InputText)
Vue.component('Button', Button)
Vue.component('Card', Card)

const Auth = namespace('auth')

@Component
export default class Login extends Vue {
  @Auth.Action('signin') dispatchSignin!: Function

  processing = false
  invalid = false
  email = ''
  password = ''

  clearErrors () {
    this.invalid = false
  }

  async signin () {
    this.processing = true
    const credentials = {
      email: this.email,
      password: this.password
    }
    try {
      await this.dispatchSignin(credentials)
      this.$router.push('/')
    } catch (err) {
      this.invalid = true
    }
    this.processing = false
  }
}
